<template>
    <div class="navbar navbar-light bg-light shadow-sm">
        <div class="container">
            <a href="#" class="navbar-brand d-flex align-items-center">
                <strong class="app-text text-center">Robot Manufacturing</strong>
            </a>
            <div class="dropdown">
                <a href="#" class="d-flex align-items-center app-text text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img :src="'https://ui-avatars.com/api/?background=random&name=' + getUser.username" alt="hugenerd" width="30" height="30" class="rounded-circle">
                    <span class="d-none d-sm-inline mx-1">{{ getUser.username }}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-light text-small shadow">
                    <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li>-->
                    <li v-if="isUserManager">
                        <router-link :to="{ name: 'backoffice' }" class="dropdown-item">
                            <i class="fa-solid fa-screwdriver-wrench"></i> Backoffice
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'profile-home' }" class="dropdown-item">
                            <i class="fa-solid fa-user"></i> Profile
                        </router-link>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li>
                        <router-link :to="{ name: 'logout' }" class="dropdown-item">
                            <i class="bi bi-box-arrow-left"></i> Log out
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()

        const isUserManager = computed(() => store.getters['users/isUserManager'])
        return {
            isUserManager,

            getUser: computed(() => store.getters['users/getUser']),
        }
    }
}
</script>

<style>

</style>