<template>
    <ModalNotifyError :id-modal="modalNotifyErrorId" @submit="reportError"/>
    <robotman-navbar-1></robotman-navbar-1>
    <div id="app-page">
    <div class="container">
        <div class="row">
            <div class="col mt-2">
                <div class="card bg-soft-info mb-3 check-info-card text-center">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <template v-if="activeEvent">
                                    <h1 class="display-4">Working on: {{ workOrder.code }} </h1>
                                </template>
                                <template v-else>
                                    <label for="select-order" class="form-label">
                                        Hello {{ getUser.username }}! Select a work order:
                                    </label>
                                    <vSelect id="select-order" class="form-control shadow-sm" :options="getWorkOrdersSelectData" 
                                            v-model="selectSelection"></vSelect>
                                </template>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6 border-end">
                                <h5 class="card-title text-primary">Item</h5>
                                <hr class="text-muted">
                                <strong v-if="workOrder" class="card-text">{{ workOrder.product }}</strong>
                                <strong v-else class="card-text">???</strong>
                            </div>
                            <div class="col-6">
                                <h5 class="card-title text-primary">Quantity</h5>
                                <hr class="text-muted">
                                <strong v-if="workOrder" class="card-text">{{ workOrder.quantity }}</strong>
                                <strong v-else class="card-text">???</strong>
                            </div>
                        </div>

                        <hr>
                        <div class="row">
                            <div class="col-3">
                            <button v-if="activeEvent"
                                    data-bs-toggle="modal" 
                                    :data-bs-target="'#' + modalNotifyErrorId"
                                    type="button" 
                                    class="btn btn-outline-warning shadow position-relative">
                                <i class="fa-solid fa-bell fa-2x"></i>
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {{ numErrorsNotified }}
                                    <span class="visually-hidden">errors reported</span>
                                </span>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="activeEvent">
            <div class="col d-flex justify-content-center">
                <TimeCounter :start_datetime="new Date(activeEvent.timestamp)" />
            </div>
        </div>
        <div class="row mt-4">
            <div class="d-grid gap-2 mx-auto">
                <template v-if="workOrder">
                    <button v-if="activeEvent" id="btn-check" 
                            @click="sendEvent"
                            type="button" class="btn btn-danger btn-block p-5 shadow">Stop</button>
                    <button v-else id="btn-check" 
                            @click="sendEvent"
                            type="button" class="btn btn-success btn-block p-5 shadow">Start</button>        
                </template>
                <template v-else>
                    <button id="btn-check"
                            type="button" class="btn btn-outline-danger btn-block p-5 shadow disabled">
                            Select a work order
                    </button>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { defineAsyncComponent, computed, ref, watch } from 'vue'
import vSelect from 'vue-select'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap'


import ModalNotifyError from '../components/ModalNotifyError'; 
import RobotmanNavbar1 from '../../shared/components/RobotmanNavbar1'; 


export default {
    components: {
        vSelect,
        ModalNotifyError,
        RobotmanNavbar1,
        TimeCounter: defineAsyncComponent(() => import('../components/TimeCounter.vue'))
    },
    setup() {
        const store = useStore()

        const selectSelection = ref(null)
        const workOrder = ref(null)
        const activeEvent = ref(null)

        const getWorkOrderById = store.getters['orders/getWorkOrderById']
        const getLastEvent = computed(() => store.getters['orders/getLastEvent'])

        const sendCheckEvent = (workOrderId) => store.dispatch('orders/sendCheckEvent', workOrderId)
        const modalNotifyErrorId = 'modal-notify-error'

        watch(selectSelection, (newSelection, oldSelection) => {
            if (!newSelection) {
                workOrder.value = null

            }else if (newSelection != oldSelection) {
                workOrder.value = getWorkOrderById(newSelection.code)

            }
        })

        watch(getLastEvent, (newLastEvent) => {
            if (newLastEvent && newLastEvent.kind && newLastEvent.kind.toLowerCase() === 'start' ) {
                let workOrderData = getWorkOrderById(newLastEvent.production_order)
                console.log(workOrderData)
                if (workOrderData) {
                    workOrder.value = workOrderData
                    selectSelection.value = { label: workOrderData.code, code: workOrderData.id }
                    console.log(`The user is working on ${workOrderData.code}`)
                    activeEvent.value = newLastEvent
                } else {
                    alert('Something is wrong!')
                }

            } else if (newLastEvent && newLastEvent.kind && newLastEvent.kind.toLowerCase() === 'stop' ) {
                console.log("Stop counter")
                activeEvent.value = null

            }
        })

        return {
            getWorkOrdersSelectData: computed(() => store.getters['orders/getWorkOrdersSelectData']),
            getLastEvent,
            getUser: computed(() => store.getters['users/getUser']),
            numErrorsNotified: computed(() => store.getters['orders/getNumErrorsNotified']),
            sendEvent: () => sendCheckEvent(workOrder.value.id),
            reportError: (_, formData) => {
                const payload = {
                    registryId: getLastEvent.value.register_id,
                    data: { text: formData.text }
                }
                store.dispatch('orders/notifyError', payload )
                Modal.getInstance(document.getElementById(modalNotifyErrorId)).hide()
            },
            modalNotifyErrorId,

            selectSelection,
            workOrder,
            activeEvent
        }
    }
}
</script>

<style lang="scss" scoped>
    .check-info-card {
        width: 100%;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .bg-soft-info {
        background-color: #f6faff;;
    }

    .vs__dropdown-toggle {
        border: none !important;
    }

    #vs1__combobox{
        border: none !important;

    }

    #app-page {
        background-color: rgba(0, 255, 64, 0.123);
        background-image:  linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('~@/assets/checkin-background.jpg');
        height: 100vh;
    }
</style>
