<template>
<BootstrapModal title="Notify error" :id-modal="idModal">
    <template v-slot:body>
        <form>
            <div class="mb-3">
                <label for="input-reference" class="form-label">Description</label>
                <BasicTextArea
                    v-model="text"
                    :invalidFeedbackText="valErrors.text"
                    textHelp="Error found description."/>
            </div>
        </form>
    </template>
    <template v-slot:footer>
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="submit">
            Notify <i class="fa-regular fa-paper-plane"></i>
        </button>
    </template>
</BootstrapModal>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';

import BootstrapModal from '../../shared/components/BootstrapModal';
import BasicTextArea from '../../shared/components/BasicTextArea';

export default {
    components: {
        BootstrapModal,
        BasicTextArea
    },
    props: {
        idModal: {
            required: true,
            type: String
        },
    },
    
    emits: ['submit'],

    setup(props, context) {
        const text = ref('')
        const valErrors = reactive({text: ''})

        const cleanValidations = () => {
            for (const field in valErrors) {
                valErrors[field] = ''
            }
        }
        
        const cleanForm = () => {
            cleanValidations()
            text.value = ''
        }
        
        const submit = ($event) => {
            cleanValidations()
            const formData = { 
                text: text.value
            }
            context.emit('submit', $event, formData)
        }

        onMounted(() => {
            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })
        })

        return {
            text,
            valErrors,

            submit
        }
    }
}
</script>

<style>

</style>